import { PageData } from '@api/hooks/types';

export const error404: Record<'ru' | 'en', PageData> = {
	ru: {
		meta: {
			title: 'Страница не найдена — 404',
			description: 'Ошибка 404',
		},
		data: [
			{
				type: 'intro',
				content: {
					title: 'Cтраница<br /><color-accent>не найдена</color-accent>',
					// breadcrumbs: [{ text: 'На главную', url: '/' }],
				},
			},
		],
	},
	en: {
		meta: {
			title: 'Page not found — 404',
			description: '404 error',
		},
		data: [
			{
				type: 'intro',
				content: {
					title: 'Page<br /><color-accent>not found</color-accent>',
					// breadcrumbs: [{ text: 'To main page', url: '/en' }],
				},
			},
		],
	},
};
