import type { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import { HeadBase } from '@components/Layout/components/Head/Head';
import { PageFlow } from '@components/Layout';
import { error404 } from '@api/mock/errors';

const Page: NextPage = () => {
	const { locale = 'ru' } = useRouter();

	const i18n = locale as 'ru' | 'en';
	const data = error404[i18n];

	return (
		<>
			<HeadBase {...data?.meta} />
			<PageFlow {...data} />
		</>
	);
};

export const getStaticProps: GetStaticProps = async () => {
	return {
		props: {
			simpleLayout: true,
		},
	};
};

export default Page;
